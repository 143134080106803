
// ===== PRINT STYLES
// ================================================================================

.header-main,
.mobile-navigation-container,
.close-mobile-container,
.footer-main {
    display: none;
}



// ===== GRIP SELECTOR - RESULTS
// ================================================================================

.page-id-4837 {
    a[href]:after,
    .gs-results-grip-img .ctrls,
    .grip-color-swatches,
    .gs-results-row1 .tab-triggers,
    .gs-results-row1 .attributes,
    .gs-results-row2 .gs-results-col2 {
        display: none;
    }

    // Primary Grip Result - Layout
    .gs-results-row1 {
        .gs-results-col {
            float: left;
            width: 50%;
            &.gs-results-col1 {
                padding-right: 15px;
            }
            &.gs-results-col2 {
                padding-left: 15px;
            }
        }
    }

    // Primary Grip Result - Force Image Display
    .gs-results-grip-img {
        .img-set {
            display: none;
            &[data-grip-color-id="1"] {
                display: block;
            }
        }
        .img {
            display: none;
            &.img-8 {
                display: block;
            }
        }
    }

    // Primary Grip Result - Tabbed Content
    .gs-results-row1 {
        line-height: 1.25;
        .tab-content .tab {
            display: block;
            padding-top: 0;
            &:first-of-type {
                padding-bottom: 0;
                p {
                    line-height: 1.5;
                }
            }
        }
    }

    // Alternative Grip Results
    .gs-results-row2 {
        width: 50%;
        margin: 0 auto;
        padding-top: 50px;
    }
}
